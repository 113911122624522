import { Controller } from "stimulus"

// Dynamically add the google api script for YouTube's subscribe button to the DOM once the partial containing it has been successfully injected via ajax
export default class extends Controller {
  connect () {
    const scriptSrc = 'https://apis.google.com/js/platform.js'
    if (document.querySelectorAll(`script[src="${scriptSrc}"]`).length > 0) return

    const googleApiScript = document.createElement('script')
    googleApiScript.setAttribute('src', scriptSrc)
    document.head.appendChild(googleApiScript)
  }
}
