// ###################
// # INPUT SMOOTH
// ###############################################################################

// https://stackoverflow.com/questions/35049555/chrome-autofill-autocomplete-no-value-for-password
// https://stackoverflow.com/questions/11708092/detecting-browser-autofill
// https://stackoverflow.com/questions/1481152/how-to-detect-a-textboxs-content-has-changed

export default class InputSmooth {
  // CONSTRUCTOR
  constructor () {
    const self = this

    this.selectorSmooth = '[data-is-input-smooth]'
    this.$inputSmooth = $(this.selectorSmooth)
    this.filledClass = 'input--filled'

    // search input smooth on DOM before launch actions
    if ($(document).find(self.selectorSmooth).length > 0) {
      // check on construction if an input is already filled or not
      // bug on saved password on IE 10
      self.$inputSmooth.each(function () {
        // standard check ; on load, check if an input is filled
        if ($(this).val().trim() !== '') {
          return self.onInputFilled($(this))

        // special check for chrome autofill (saved password) when page is loaded
        // make it in a try because pseudo-selector ":-webkit-autofill" is only recognized by webkit browsers and throw errors on other browsers
        } else {
          // only check on password type, because other types doesn't bug
          if ($(this).attr('type') === 'password') {
            const inputCurrent = $(this)
            setTimeout(function () {
              try {
                if (inputCurrent.is(':-webkit-autofill')) {
                  return self.onInputFilled(inputCurrent)
                }
              } catch (error) {}
            }, 100)
          }
        }
      })

      // bind events
      this.bindEvents()
    }
  }

  // BIND EVENTS
  // on focus & on leave
  bindEvents () {
    const self = this

    // bind events for each input
    return this.$inputSmooth.each(function () {
      // on focus input add filledClass
      // input event for autofilled forms (like saved passwords) when user enter his-her mail
      $(this).on('input focus', function () {
        return self.onInputFilled($(this))
      })

      // on leave input; check if input is filled. if not filled, remove filledclass
      return $(this).on('blur', function () {
        if ($(this).val().trim() === '') {
          return self.onInputEmpty($(this))
        }
      })
    })
  }

  onInputFilled (input) {
    return input.addClass(this.filledClass)
  }

  onInputEmpty (input) {
    return input.removeClass(this.filledClass)
  }
};
