import VanillaModal from 'vanilla-modal'

export default class {
  constructor () {
    this.$modals = document.querySelectorAll('.modal-btn')

    if (this.$modals) {
      this.bindEvents()
    }
  }

  bindEvents () {
    new VanillaModal({
      modal: '.modal-template',
      modalInner: '.modal-template__inner',
      modalContent: '.modal-template__content'
    })
  }
}
