import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["button", "buttonText"]

  create () {
    $.ajax({
      url: this.data.get("url"),
      type: "post",
      dataType: "json",
      success: (response) => {
        $(this.buttonTarget).removeClass('btn-primary').addClass('btn-success')
        $(this.buttonTextTarget).html(response.message)
      }
    })
  }
}
