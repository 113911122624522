import { Controller } from 'stimulus'

export default class extends Controller {
  replace (event) {
    event.preventDefault()

    const [, , xhr] = event.detail
    this.element.outerHTML = xhr.response

    this.displayFlashMessage(event)
  }

  append (event) {
    event.preventDefault()

    const [, , xhr] = event.detail
    this.element.insertAdjacentHTML('afterend', xhr.response)

    this.displayFlashMessage(event)
  }

  prepend (event) {
    event.preventDefault()

    const [, , xhr] = event.detail
    this.element.insertAdjacentHTML('beforebegin', xhr.response)

    this.displayFlashMessage(event)
  }

  displayFlashMessage (event) {
    new FlashMessage(event.currentTarget.dataset.notice, 'success')
  }
}
