import LazyLoad from 'vanilla-lazyload'

export default () => {
  new LazyLoad({
    elements_selector: ".lazy-load",
    use_native: true,
    callback_loaded: (el) => {
      el.classList.remove('lazy')
    }
  })
}
