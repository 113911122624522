import Vue from 'vue'

// Plugins
import '@plugins/font-awesome'
import Transitions from 'vue2-transitions'
import VueMq from 'vue-mq'
import VueScrollTo from 'vue-scrollto'
import PrettyCheckbox from 'pretty-checkbox-vue'
import VueAgile from 'vue-agile'
import { VueStars } from "vue-stars"

// Directive
import vClickOutside from 'v-click-outside'
/**
 * Automatically register components in the "pages/application" folder.
*/
const files = require.context('./', true, /\.vue$/i)
files.keys().forEach(key => {
  const component = key.split('/').pop().split('.')[0]

  Vue.component(component, () => import(`${key}`))
})

// Components
Vue.component('Lightgallery', () => import('@components/Lightgallery'))
Vue.component('ActiveItem', () => import('@components/ActiveItem'))
Vue.component('PromotionalBanner', () => import('@components/PromotionalBanner'))
Vue.component('SelectExample', () => import('@components/SelectExample'))
Vue.component('BuySelect', () => import('@components/searchbar/BuySelect'))
Vue.component('WhereSelect', () => import('@components/searchbar/WhereSelect'))
Vue.component('CustomMap', () => import('@components/CustomMap'))
Vue.component('CustomMapMarker', () => import('@components/CustomMapMarker'))
Vue.component('MassenaMapMarker', () => import('@components/MassenaMapMarker'))
Vue.component('ValpergaMapMarker', () => import('@components/ValpergaMapMarker'))
Vue.component('FelixFaureMapMarker', () => import('@components/FelixFaureMapMarker'))
Vue.component('InstagramFeed', () => import('@components/InstagramFeed'))
Vue.component("VueStars", VueStars)

Vue.use(Transitions)
Vue.use(VueMq, {
  breakpoints: {
    mobile: 1280,
    desktop: Infinity
  }
})
Vue.use(vClickOutside)
Vue.use(VueScrollTo)
Vue.use(PrettyCheckbox)
Vue.use(VueAgile)

// Configuration
Vue.config.performance = true
Vue.config.productionTip = false

document.addEventListener('DOMContentLoaded', () => {
  new Vue({
    el: '#vue-app'
  })
})
