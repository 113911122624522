// #############################
// CUSTOM FILE INPUT LIST
// #############################

// ######## GENERATE EACH CUSTOM FILE INPUT
import CustomFileInput from "@utils/custom-file-input"

export default () => {
  $("[data-is-custom-file-input-wrapper]").each(function () {
    new CustomFileInput($(this))
  })
}
