import { gsap, TweenLite, CSSPlugin } from "gsap/all"

gsap.registerPlugin(CSSPlugin)

export default class {
  constructor () {
    // DOM
    this.$searchAccommodations = document.querySelector('.search-bar--accommodations')
    this.body = document.body
    this.html = document.documentElement
    this.$openButton = document.querySelector('.search-mobile__button')
    this.$closeButton = document.querySelector('.search-bar__close')

    // BOOL
    this.isOpen = true

    // #### bind events
    if (this.$searchAccommodations) {
      this.bindEvents()
    }
  }

  // ####### bind events : on click
  bindEvents () {
    // ### on change, open header mobile
    this.$closeButton.addEventListener('click', this.searchMobileOpenAnimation.bind(this))

    this.$openButton.addEventListener('click', this.searchMobileOpenAnimation.bind(this))
  }

  searchMobileOpenAnimation () {
    TweenLite.to(this.$searchAccommodations, 0.5, { y: this.isOpen ? '0%' : '100%' })
    this.body.classList.toggle("overflow-hidden-body", !this.isOpen)
    this.html.classList.toggle("overflow-hidden-html", !this.isOpen)
    this.isOpen = !this.isOpen
  }
}
