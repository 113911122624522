import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["redirectPath", "form"]

  error (event) {
    const [response, , xhr] = event.detail

    if (xhr && xhr.status === 422) {
      this.formTarget.innerHTML = response
    } else {
      alert("Une erreur technique s'est produite. Veuillez réessayer ultérieurement")
    }
  }

  success (event) {
    document.location.href = this.redirectPathTarget.value
  }
}
