export default class {
  constructor () {
    this.slider = document.querySelector('.pagination-slider')
    this.sliderContainer = document.querySelector('.agile__slides--regular')
    this.slides = this.sliderContainer.querySelectorAll('.pagination-slider__slide')
    this.slidesTotal = this.sliderContainer.querySelectorAll('.pagination-slider__total')

    if (this.slider !== null) {
      this.bindEvents()
    }
  }

  bindEvents () {
    // Set total slide on each slides
    this.slidesTotal.forEach(element => {
      element.innerHTML = this.slides.length
    })
    // Set current slide on each slides
    this.slides.forEach((element, index) => {
      element.querySelector('.pagination-slider__current').innerHTML = index + 1
    })
  }
}
