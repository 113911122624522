export default () => {
  const element = document.querySelectorAll('[data-animate]')
  if (element === null) return

  const intersectionObserverScroll = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        entry.target.classList.add('in')
      }
    })
  }, {
    rootMargin: '0px'
  })

  element.forEach(animateElement => intersectionObserverScroll.observe(animateElement))
}
