import Vue from 'vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faUser,
  faInfoCircle,
  faTimesCircle,
  faBell,
  faSort
} from '@fortawesome/pro-solid-svg-icons'

import {
  faFacebookF,
  faTwitter,
  faYoutube,
  faInstagram,
  faLinkedinIn
} from '@fortawesome/free-brands-svg-icons'

import {
  faTimes,
  faBlog
} from '@fortawesome/pro-regular-svg-icons'

import {
  faUser as falUser,
  faTimes as falTimes,
  faChevronRight,
  faImages,
  faHome,
  faRulerVertical,
  faPlus,
  faEuroSign,
  faHeart
} from '@fortawesome/pro-light-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(
  faUser,
  falUser,
  faChevronRight,
  faInfoCircle,
  faTimes,
  falTimes,
  faImages,
  faHome,
  faYoutube,
  faRulerVertical,
  faFacebookF,
  faTwitter,
  faInstagram,
  faLinkedinIn,
  faPlus,
  faEuroSign,
  faTimesCircle,
  faBell,
  faSort,
  faHeart,
  faBlog
)

Vue.component('FontAwesomeIcon', FontAwesomeIcon)
