import { gsap, TweenLite, CSSPlugin } from "gsap/all"

gsap.registerPlugin(CSSPlugin)

export default class {
  constructor () {
    this.body = document.body
    this.html = document.documentElement
    this.header = document.querySelector('.header')
    this.headerCross = document.querySelector('.header__cross')
    this.headerMainLink = document.querySelectorAll('.header__main-link:not(.header__main-link--submenu)')
    this.headerMainLinkSubmenu = document.querySelectorAll('.header__main-link--submenu')
    this.headerList = document.querySelectorAll('.header__secondary-list')
    this.headerClosed = document.querySelector('.header__closed')

    if (this.header !== null) {
      this.bindEvents()
    }
  }

  bindEvents () {
    // OnClick burger action
    this.headerCross.addEventListener('click', () => { this.toggleBurgerMenu() })

    // OnClick closed div -> closeAllSubmenu
    this.headerClosed.addEventListener('click', () => { this.closeAllSubmenu() })

    // OnMouseove main link without submenu -> closeAllSubmenu
    this.headerMainLink.forEach(element => {
      element.addEventListener('mouseover', () => { this.closeAllSubmenu() })
    })

    // OnClick main link open submenu animation
    this.headerMainLinkSubmenu.forEach(element => {
      element.addEventListener('click', () => {
        if (this.isDesktop()) return

        this.toggleAnimation(element)
      })

      element.addEventListener('mouseover', () => {
        if (!this.isDesktop()) return

        this.toggleAnimation(element)
      })
    })

    this.headerList.forEach(element => {
      element.addEventListener('mouseleave', (e) => {
        if (!this.isDesktop()) return

        this.toggleAnimation(e.target.parentElement.querySelector('.header__main-link--submenu'))
      })
    })

    window.addEventListener('resize', () => { this.closeAllSubmenu() })
  }

  // Chose if submenu should be close or open
  toggleAnimation (element) {
    const secondaryList = element.parentElement.querySelector('.header__secondary-list')

    if (secondaryList.classList.contains('active')) {
      this.closeAnimation(secondaryList)
    } else {
      this.openAnimation(secondaryList)
    }
  }

  // Tween animation submenu close
  closeAnimation (element) {
    if (this.isDesktop()) {
      TweenLite.to(this.headerClosed, 0, { display: 'none' })
      TweenLite.to(element, 0.2, { opacity: 0, y: 50, x: '-30%', pointerEvents: 'none' })
    } else {
      TweenLite.to(element, 0.2, { height: 0 })
    }

    element.classList.toggle('active')
  }

  // Tween animation submenu open
  openAnimation (element) {
    this.resetTween(element)
    this.closeAllSubmenu()

    if (this.isDesktop()) {
      TweenLite.to(this.headerClosed, 0, { display: 'block' })
      TweenLite.to(element, 0.2, { opacity: 1, y: 0, x: '-30%', pointerEvents: 'auto' })
    } else {
      TweenLite.set(element, { height: 'auto' })
      TweenLite.from(element, 0.5, { height: 0 })
    }

    element.classList.toggle('active')
  }

  // Open mobile expand nav + block scroll body
  toggleBurgerMenu () {
    this.header.classList.toggle('active')
    this.body.classList.toggle("overflow-hidden-body")
    this.html.classList.toggle("overflow-hidden-html")
  }

  // Close all open subemenu
  closeAllSubmenu () {
    this.headerMainLinkSubmenu.forEach(element => {
      const currentSubmenu = element.parentElement.querySelector('.header__secondary-list')

      if (currentSubmenu !== null && currentSubmenu.classList.contains('active')) {
        this.closeAnimation(currentSubmenu)
      }
    })
  }

  resetTween (element) {
    TweenLite.set(element, { clearProps: 'all' })
  }

  isDesktop () {
    return window.innerWidth >= 1280
  }
}
