/* eslint no-console:0 */

// MODULES =====================================================================
import Rails from 'rails-ujs'
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import "@appPages/vue"
import VanillaModal from 'vanilla-modal'

import "stylesheets/application.sass"
import AnimationsScroll from "@utils/animations-scroll"
import CustomFileInputList from "@utils/custom-file-input-list"
import InputSmooth from "@utils/input-smooth"
import ImageObjectFit from "@utils/image-object-fit"
import FlashMessage from "@utils/flash-messages"
import MainHeader from "@utils/main-header"
import ImageLoad from "@utils/image-load"
import Modal from "@utils/share-modal"
import HeaderComponent from "@utils/header-component"
import SliderHomeComponent from "@utils/pagination-slider"
import AccommodationsSearchComponent from "@utils/accommodations-search"
import executeRecaptcha from "@utils/grecaptcha"

// Fonts ===============================================================
import "typeface-roboto"

// CONFIGURATION ===============================================================
Rails.start()
const application = Application.start()
const context = require.context("controllers", true, /\.js$/)
application.load(definitionsFromContext(context))

window.FlashMessage = FlashMessage
window.executeRecaptcha = executeRecaptcha

document.addEventListener("DOMContentLoaded", () => {
  new CustomFileInputList()
  new InputSmooth()
  new ImageObjectFit()
  new ImageLoad()
  new Modal()
  new MainHeader()
  new HeaderComponent()
  new AccommodationsSearchComponent()
  new AnimationsScroll()
  new SliderHomeComponent()

  if (document.getElementById("notice-modal")) {
    // Open home modal
    const modal = new VanillaModal({
      modal: '.modal-template',
      modalInner: '.modal-template__inner',
      modalContent: '.modal-template__content'
    })
    modal.open('#notice-modal')
  }
})


